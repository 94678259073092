
	import { Component, Mixins } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import { PaymentChannel, SUBSCRIPTION, NAMESPACE as USER_NAMESPACE, UserState } from '@pixcap/ui-core/models/store/user.interface';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import UpgradeSubscriptionMixin from '@pixcap/ui-core/components/payment/UpgradeSubscriptionMixin';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import { _PIXCAP_ENV } from '@pixcap/ui-core/env';

	@Component({
		name: 'PortalPaymentStatusModal',
		components: {
			Button,
			HeadingText,
			BodyText,
			BaseModal,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				showPortalPaymentStatus: (state: UserState) => state.showPortalPaymentStatus,
			}),
		},
	})
	export default class PortalPaymentStatusModal extends Mixins(UpgradeSubscriptionMixin) {
		showPortalPaymentStatus: UserState['showPortalPaymentStatus'];
		imgUrl = `${_PIXCAP_ENV.ROOT_URL}/cdn/app/pricing/subscription-change-icon.png`;

		get topUpQuantity() {
			return this.showPortalPaymentStatus.topUpCreditsQuantity;
		}

		get isBuyItemEvent() {
			return this.showPortalPaymentStatus.isBuyItemEvent;
		}

		get isTopUpEvent() {
			return this.showPortalPaymentStatus.isTopUpEvent;
		}

		get subscriptionType() {
			return [SUBSCRIPTION.ELITE_MONTHLY, SUBSCRIPTION.ELITE_QUARTERLY, SUBSCRIPTION.ELITE_ANNUALLY].includes(
				this.showPortalPaymentStatus.subscription
			)
				? 'ELITE'
				: 'PRO';
		}

		get isUpdatedSubscription() {
			return this.showPortalPaymentStatus.subscription == this.subscription;
		}

		get isTopupUpdated() {
			return this.getAvailableCredits == this.showPortalPaymentStatus.topUpCreditsQuantity + this.showPortalPaymentStatus.initialCredits;
		}

		get paymentStatusDescription() {
			if (this.isBuyItemEvent) {
				return 'May take a few moments for purchased items to update';
			}
			return this.isUpdatedSubscription || this.isTopupUpdated
				? `Let’s rock your creative world! 🚀`
				: this.isTopUpEvent
				? `May take a few moments for ${this.showPortalPaymentStatus.topUpCreditsQuantity} topped up credits to be added`
				: 'May take a few moments for subscription to update';
		}

		get isStripeSubscription() {
			return this.showPortalPaymentStatus.channel == PaymentChannel.STRIPE;
		}

		async manageSubscription() {
			await this.openCustomerPortal();
			this.handleCloseModal();
		}

		handleCloseModal() {
			UserMutations.showPortalPaymentStatusModal(this.$store, null);
		}
	}
