import { render, staticRenderFns } from "./PortalPaymentStatusModal.vue?vue&type=template&id=38b6fc60&scoped=true&"
import script from "./PortalPaymentStatusModal.vue?vue&type=script&lang=ts&"
export * from "./PortalPaymentStatusModal.vue?vue&type=script&lang=ts&"
import style0 from "./PortalPaymentStatusModal.vue?vue&type=style&index=0&id=38b6fc60&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38b6fc60",
  null
  
)

export default component.exports