
	import { Component, Vue } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';
	import {
		UserState,
		NAMESPACE as USER_NAMESPACE,
		SUBSCRIPTION,
		GetterTypes as UserGettersTypes,
		Getters as UserGetters,
	} from '@pixcap/ui-core/models/store/user.interface';
	import { mutationsWrapper as UserMutations, actionsWrapper as UserActions } from '@pixcap/ui-core/store/user';
	import { LEAVE_REASON } from '@pixcap/ui-core/constants/trial.constant';

	import { formatTime } from '@pixcap/ui-core/utils/DatetimeUtils';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import RadioBox from '@pixcap/ui-library/components/RadioBox.vue';
	import EditableTextarea from '@pixcap/ui-library/components/EditableTextarea.vue';
	import AIImagesCarousel from '@pixcap/ui-core/components/payment/shared/AIImagesCarousel.vue';

	import IconCircleCheck from '@pixcap/ui-library/components/Icons/IconCircleCheck.vue';
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';
	import { _PIXCAP_ENV } from '@pixcap/ui-core/env';
	import IconCheck from '@pixcap/ui-library/components/Icons/IconCheck.vue';

	@Component({
		name: 'CancelPlanModal',
		components: {
			AIImagesCarousel,
			BaseModal,
			CaptionText,
			BodyText,
			HeadingText,
			EditableTextarea,
			RadioBox,
			Button,
			//
			IconCircleCheck,
			IconClose,
			IconCheck,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				subscriptionInfo: (state: UserState) => state.subscriptionInfo,
			}),
			...mapGetters(USER_NAMESPACE, {
				isSubscribedWithStripe: UserGettersTypes.IS_SUBSCRIBED_WITH_STRIPE,
				isSubscribedWithPaypal: UserGettersTypes.IS_SUBSCRIBED_WITH_PAYPAL,
				isSubscribedWithXendit: UserGettersTypes.IS_SUBSCRIBED_WITH_XENDIT,
				isSubscribedWithTazapay: UserGettersTypes.IS_SUBSCRIBED_WITH_TAZAPAY,
				isSubscribedWithPaddle: UserGettersTypes.IS_SUBSCRIBED_WITH_PADDLE,
			}),
		},
	})
	export default class CancelPlanModal extends Vue {
		subscriptionInfo: UserState['subscriptionInfo'];
		isSubscribedWithStripe: ReturnType<UserGetters[UserGettersTypes.IS_SUBSCRIBED_WITH_STRIPE]>;
		isSubscribedWithPaypal: ReturnType<UserGetters[UserGettersTypes.IS_SUBSCRIBED_WITH_PAYPAL]>;
		isSubscribedWithXendit: ReturnType<UserGetters[UserGettersTypes.IS_SUBSCRIBED_WITH_XENDIT]>;
		isSubscribedWithTazapay: ReturnType<UserGetters[UserGettersTypes.IS_SUBSCRIBED_WITH_TAZAPAY]>;
		isSubscribedWithPaddle: ReturnType<UserGetters[UserGettersTypes.IS_SUBSCRIBED_WITH_PADDLE]>;
		illustrationUrl = `${_PIXCAP_ENV.ROOT_URL}/cdn/app/authentication/thumbnail.webp`;

		reasonSelected = null;
		cancelationStep = 0;
		cancelPlanMessage = '';

		get leaveReason() {
			return [
				// @ts-ignore
				this.$t ? this.$t('page.user_settings.subscription.cancel_sub_modal.reason.reason_1') : 'It’s too expensive',
				// @ts-ignore
				this.$t ? this.$t('page.user_settings.subscription.cancel_sub_modal.reason.reason_2') : 'I need more features',
				// @ts-ignore
				this.$t ? this.$t('page.user_settings.subscription.cancel_sub_modal.reason.reason_3') : 'I found another similar website',
				// @ts-ignore
				this.$t ? this.$t('page.user_settings.subscription.cancel_sub_modal.reason.reason_4') : 'I don’t use it that often',
				// @ts-ignore
				this.$t ? this.$t('page.user_settings.subscription.cancel_sub_modal.reason.reason_5') : 'There are not many quality resources',
				// @ts-ignore
				this.$t
					? // @ts-ignore
					  this.$t('page.user_settings.subscription.cancel_sub_modal.reason.reason_6', { plan: this.isProSubscription ? 'PRO' : 'ELITE' })
					: `I don't think Pixcap ${this.isProSubscription ? 'PRO' : 'ELITE'} is worth the money`,
				// @ts-ignore
				this.$t ? this.$t('page.user_settings.subscription.cancel_sub_modal.reason.reason_7') : 'I found another similar website',
			];
		}

		get features() {
			const isProSubscription = this.isProSubscription;
			return [
				{
					title: `${isProSubscription ? '<strong>600</strong>' : '<strong>2000</strong>'} <strong>${
						// @ts-ignore
						this.$t ? this.$t('page.upgrade_plan.credit_based_features.monthly_credits') : 'monthly credits'

						// @ts-ignore
					}</strong> ${this.$t ? this.$t('page.upgrade_plan.credit_based_features.super_and_ai') : '(Super Downloads & Pixcap AI)'}`,
				},
				{
					title:
						'<strong>' + isProSubscription
							? // @ts-ignore
							  this.$t
								? // @ts-ignore
								  this.$t('page.upgrade_plan.benefits.upto_50_projects')
								: 'Up to 50 projects'
							: // @ts-ignore
							this.$t
							? // @ts-ignore
							  this.$t('page.upgrade_plan.benefits.unlimited_projects')
							: 'Unlimited projects' + '</strong>',
				},
				{
					title:
						'<strong>' + isProSubscription
							? // @ts-ignore
							  this.$t
								? // @ts-ignore
								  this.$t('page.upgrade_plan.benefits.super_download')
								: '3x-faster Super Downloads'
							: // @ts-ignore
							this.$t
							? // @ts-ignore
							  this.$t('page.upgrade_plan.benefits.export_3d_files')
							: 'Export 3D files' + '</strong>',
				},
				// @ts-ignore
				{ title: this.$t ? this.$t('page.upgrade_plan.benefits.all_premiums') : `All premium templates & assets` },
				// @ts-ignore
				{ title: this.$t ? this.$t('page.upgrade_plan.benefits.full_commercial_license') : `Full commercial license` },
			];
		}

		get expireDay() {
			return formatTime(this.subscriptionInfo.currentPeriodEnd);
		}

		get isProSubscription() {
			const subscription = this.subscriptionInfo?.subscription;
			return subscription === SUBSCRIPTION.PRO_ANNUALLY || subscription === SUBSCRIPTION.PRO_MONTHLY || subscription === SUBSCRIPTION.PRO_QUARTERLY;
		}

		get disableCancelPlan() {
			return this.reasonSelected == null && this.cancelationStep == 1;
		}

		handleCancelPlan() {
			if (this.cancelationStep == 0) {
				this.cancelationStep = 1;
				return;
			}
			const reason = LEAVE_REASON[this.reasonSelected];
			UserActions.cancelSubscription(this.$store, reason);
			// if (this.cancelPlanMessage) UserActions.addCancelSubscriptionReason(this.$store, this.cancelPlanMessage);
			this.cancelationStep = 2;
		}

		selectReason(reasonIdx) {
			this.reasonSelected = reasonIdx;
		}

		handleCloseCancelModal() {
			UserMutations.toggleCancelPlanModal(this.$store);
		}
	}
