import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { actionsWrapper as UserActions, mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
import {
	Getters as UserGetters,
	GetterTypes as UserGetterTypes,
	GetterTypes as UserGettersTypes,
	NAMESPACE as USER_NAMESPACE,
	PAYMENT_STATUS,
	PaymentChannel,
	PlanRanges,
	PORTAL_PAYMENT_STATUS,
	SUBSCRIPTION,
	UserState,
} from '@pixcap/ui-core/models/store/user.interface';
import { Getters as AuthGetters, GetterTypes as AuthGetterTypes, NAMESPACE as AUTH_NAMESPACE } from '@pixcap/ui-core/models/store/auth.interface';
import {
	AppState,
	Getters as AppGetters,
	GetterTypes as AppGetterTypes,
	NAMESPACE as APP_NAMESPACE,
	SpecialPromotionType,
} from '@pixcap/ui-core/models/store/app.interface';
import { CREDITS_REQUIRED_PER_SECOND_FOR_VIDEO_EXPORT, STRIPE_UNSUPPORTED_COUNTRIES } from '@pixcap/ui-core/constants/subscription.constants';
import { initializePaddle, Paddle } from '@paddle/paddle-js';
import { _PIXCAP_ENV } from '@pixcap/ui-core/env';
import { PORTAL_PAYEMNT_REDIRECT_URL } from '@pixcap/ui-core/constants/payment.constants';

function sanitizePrice(rawPrice: number) {
	if (rawPrice % 1 == 0) {
		return rawPrice.toLocaleString('en-US');
	} else {
		return Number(rawPrice.toFixed(2)).toLocaleString('en-US');
	}
}

@Component({
	name: 'UpgradeSubscriptionMixin',
	computed: {
		...mapState(USER_NAMESPACE, {
			upgradeModalState: (state: UserState) => state.upgradeModalState,
			subscriptionInfo: (state: UserState) => state.subscriptionInfo,
			selectedSubscriptionForPayment: (state: UserState) => state.selectedSubscriptionForPayment,
			toppedCreditsQuantityForPayment: (state: UserState) => state.toppedCreditsQuantityForPayment,
			cartItems: (state: UserState) => state.cartItems,
		}),
		...mapState(APP_NAMESPACE, {
			locale: (state: AppState) => state.locale,
		}),
		...mapGetters(AUTH_NAMESPACE, {
			isAuthenticated: AuthGetterTypes.IS_AUTHENTICATED,
		}),
		...mapGetters(USER_NAMESPACE, {
			isUnsubscribedUser: UserGetterTypes.IS_UNSUBSCRIBED_USER,
			isSubscribedWithStripe: UserGettersTypes.IS_SUBSCRIBED_WITH_STRIPE,
			isSubscribedWithPaypal: UserGettersTypes.IS_SUBSCRIBED_WITH_PAYPAL,
			isSubscribedWithXendit: UserGettersTypes.IS_SUBSCRIBED_WITH_XENDIT,
			isSubscribedWithTazapay: UserGettersTypes.IS_SUBSCRIBED_WITH_TAZAPAY,
			isSubscribedWithPaddle: UserGettersTypes.IS_SUBSCRIBED_WITH_PADDLE,
			getPricingForUser: UserGettersTypes.GET_PRICING_FOR_USER,
			overridingPricingCountryCode: UserGettersTypes.OVERRIDING_PRICING_COUNTRY_CODE,
			getAvailableCredits: UserGetterTypes.GET_AVAILABLE_CREDITS,
			isPro: UserGettersTypes.IS_PRO,
			isElite: UserGettersTypes.IS_ELITE,
		}),
		...mapGetters(APP_NAMESPACE, {
			currentPromotion: AppGetterTypes.CURRENT_PROMOTION,
			codePromotion: AppGetterTypes.CODE_PROMOTION,
		}),
	},
})
export default class UpgradeSubscriptionMixin extends Vue {
	upgradeModalState: UserState['upgradeModalState'];
	subscriptionInfo: UserState['subscriptionInfo'];
	selectedSubscriptionForPayment: UserState['selectedSubscriptionForPayment'];
	toppedCreditsQuantityForPayment: UserState['toppedCreditsQuantityForPayment'];
	cartItems: UserState['cartItems'];
	locale: AppState['locale'];

	codePromotion: ReturnType<AppGetters[AppGetterTypes.CODE_PROMOTION]>;
	currentPromotion: ReturnType<AppGetters[AppGetterTypes.CURRENT_PROMOTION]>;
	isAuthenticated: ReturnType<AuthGetters[AuthGetterTypes.IS_AUTHENTICATED]>;
	isUnsubscribedUser: ReturnType<UserGetters[UserGetterTypes.IS_UNSUBSCRIBED_USER]>;
	isSubscribedWithStripe: ReturnType<UserGetters[UserGettersTypes.IS_SUBSCRIBED_WITH_STRIPE]>;
	isSubscribedWithPaypal: ReturnType<UserGetters[UserGettersTypes.IS_SUBSCRIBED_WITH_PAYPAL]>;
	isSubscribedWithXendit: ReturnType<UserGetters[UserGettersTypes.IS_SUBSCRIBED_WITH_XENDIT]>;
	isSubscribedWithTazapay: ReturnType<UserGetters[UserGettersTypes.IS_SUBSCRIBED_WITH_TAZAPAY]>;
	isSubscribedWithPaddle: ReturnType<UserGetters[UserGettersTypes.IS_SUBSCRIBED_WITH_PADDLE]>;
	getPricingForUser: ReturnType<UserGetters[UserGettersTypes.GET_PRICING_FOR_USER]>;
	getAvailableCredits: ReturnType<UserGetters[UserGetterTypes.GET_AVAILABLE_CREDITS]>;
	overridingPricingCountryCode: ReturnType<UserGetters[UserGettersTypes.OVERRIDING_PRICING_COUNTRY_CODE]>;
	isPro: ReturnType<UserGetters[UserGettersTypes.IS_PRO]>;
	isElite: ReturnType<UserGetters[UserGettersTypes.IS_ELITE]>;

	CREDITS_REQUIRED_PER_SECOND_FOR_VIDEO_EXPORT = CREDITS_REQUIRED_PER_SECOND_FOR_VIDEO_EXPORT;
	monthlyOrQuarterlyOn = false;
	isLoading = false;
	isLoadingState = {};
	selectedSubscriptionInfo = null;
	topUpCreditsInfo = null;
	cartInfo = null;
	messagePortalUpgradeListener = null;
	handleCloseMethod = null;

	paddle: Paddle | null = null;

	get payWithCreditCardChannel() {
		return STRIPE_UNSUPPORTED_COUNTRIES.includes(this.overridingPricingCountryCode) ? PaymentChannel.PADDLE : PaymentChannel.STRIPE;
	}

	get isCodePromotionActive() {
		return this.codePromotion !== null;
	}

	get isPromotionActive() {
		return this.currentPromotion !== null && this.currentPromotion.type === SpecialPromotionType.DISCOUNT_PROMO;
	}

	get availableDiscount() {
		if (this.isPromotionActive) return this.currentPromotion.discount;
		return undefined;
	}

	get isDiscountForAnnuallyPlanOnly() {
		if (this.isPromotionActive) {
			return this.currentPromotion.planApply === PlanRanges.ANNUALLY;
		}
		return false;
	}

	get pricingBaseInfo() {
		const monthlyOrQuarterlyOn = this.monthlyOrQuarterlyOn;
		const pricing = this.pricing;
		return {
			pro: {
				price: monthlyOrQuarterlyOn ? pricing.pro.monthly?.priceAfterDiscount : pricing.pro.annually.priceAfterDiscount,
				priceBeforeDiscount: monthlyOrQuarterlyOn ? null : pricing.pro.monthly?.priceBeforeDiscount,
				billingText: monthlyOrQuarterlyOn
					? !pricing.pro.isQuarterlyPlanAvailable
						? null
						: // @ts-ignore
						this.$t
						? // @ts-ignore
						  this.$t('page.upgrade_plan.bill_quarterly', { amount: `${pricing.currencyPrefix}${pricing.pro.monthly?.paymentPrice} ` })
						: `Billed ${pricing.currencyPrefix}${pricing.pro.monthly?.paymentPrice} for 3 months`
					: // @ts-ignore
					this.$t
					? // @ts-ignore
					  this.$t('page.upgrade_plan.bill_annually', { amount: `${pricing.currencyPrefix}${pricing.pro.annually.paymentPrice}` })
					: `Billed ${pricing.currencyPrefix}${pricing.pro.annually.paymentPrice} annually`,
				plan: monthlyOrQuarterlyOn ? pricing.pro.monthly?.plan : pricing.pro.annually.plan,
			},
			elite: {
				price: monthlyOrQuarterlyOn ? pricing.elite.monthly?.priceAfterDiscount : pricing.elite.annually.priceAfterDiscount,
				priceBeforeDiscount: monthlyOrQuarterlyOn ? null : pricing.elite.monthly?.priceBeforeDiscount,
				billingText: monthlyOrQuarterlyOn
					? !pricing.elite.isQuarterlyPlanAvailable
						? null
						: // @ts-ignore
						this.$t
						? // @ts-ignore
						  this.$t('page.upgrade_plan.bill_quarterly', { amount: `${pricing.currencyPrefix}${pricing.elite.monthly?.paymentPrice}` })
						: `Billed ${pricing.currencyPrefix}${pricing.elite.monthly?.paymentPrice} for 3 months`
					: // @ts-ignore
					this.$t
					? // @ts-ignore
					  this.$t('page.upgrade_plan.bill_annually', { amount: `${pricing.currencyPrefix}${pricing.elite.annually.paymentPrice}` })
					: `Billed ${pricing.currencyPrefix}${pricing.elite.annually.paymentPrice} annually`,
				plan: monthlyOrQuarterlyOn ? pricing.elite.monthly?.plan : pricing.elite.annually.plan,
			},
		};
	}

	get pricing() {
		const PRICING = this.getPricingForUser;
		const pricing: any = {
			currencyPrefix: PRICING.CURRENCY_PREFIX,
			pro: {
				isQuarterlyPlanAvailable: PRICING.PRO_MONTH == undefined,
				monthly:
					PRICING.PRO_MONTH != undefined || PRICING.PRO_QUARTER != undefined
						? {
								priceBeforeDiscount: sanitizePrice(PRICING.PRO_MONTH ?? PRICING.PRO_QUARTER / 3),
								priceAfterDiscount: sanitizePrice(
									(PRICING.PRO_MONTH ?? PRICING.PRO_QUARTER / 3) -
										(PRICING.PRO_MONTH ?? PRICING.PRO_QUARTER / 3) *
											(this.availableDiscount ? (this.isDiscountForAnnuallyPlanOnly ? 0 : this.availableDiscount / 100) : 0)
								),
								paymentPrice: sanitizePrice(
									(PRICING.PRO_MONTH ?? PRICING.PRO_QUARTER) -
										(PRICING.PRO_MONTH ?? PRICING.PRO_QUARTER) *
											(this.availableDiscount ? (this.isDiscountForAnnuallyPlanOnly ? 0 : this.availableDiscount / 100) : 0)
								),
								plan: PRICING.PRO_MONTH ? SUBSCRIPTION.PRO_MONTHLY : SUBSCRIPTION.PRO_QUARTERLY,
						  }
						: undefined,
				annually: {
					priceBeforeDiscount: sanitizePrice(PRICING.PRO_ANNUAL / 12),
					priceAfterDiscount: sanitizePrice(
						PRICING.PRO_ANNUAL / 12 - (PRICING.PRO_ANNUAL / 12) * (this.availableDiscount ? this.availableDiscount / 100 : 0)
					),
					paymentPrice: sanitizePrice(PRICING.PRO_ANNUAL - PRICING.PRO_ANNUAL * (this.availableDiscount ? this.availableDiscount / 100 : 0)),
					plan: SUBSCRIPTION.PRO_ANNUALLY,
				},
			},
			elite: {
				isQuarterlyPlanAvailable: PRICING.ELITE_MONTH == undefined,
				monthly:
					PRICING.ELITE_MONTH != undefined || PRICING.ELITE_QUARTER != undefined
						? {
								priceBeforeDiscount: sanitizePrice(PRICING.ELITE_MONTH ?? PRICING.ELITE_QUARTER / 3),
								priceAfterDiscount: sanitizePrice(
									(PRICING.ELITE_MONTH ?? PRICING.ELITE_QUARTER / 3) -
										(PRICING.ELITE_MONTH ?? PRICING.ELITE_QUARTER / 3) *
											(this.availableDiscount ? (this.isDiscountForAnnuallyPlanOnly ? 0 : this.availableDiscount / 100) : 0)
								),
								paymentPrice: sanitizePrice(
									(PRICING.ELITE_MONTH ?? PRICING.ELITE_QUARTER) -
										(PRICING.ELITE_MONTH ?? PRICING.ELITE_QUARTER) *
											(this.availableDiscount ? (this.isDiscountForAnnuallyPlanOnly ? 0 : this.availableDiscount / 100) : 0)
								),
								plan: PRICING.ELITE_MONTH ? SUBSCRIPTION.ELITE_MONTHLY : SUBSCRIPTION.ELITE_QUARTERLY,
						  }
						: undefined,
				annually: {
					priceBeforeDiscount: sanitizePrice(PRICING.ELITE_ANNUAL / 12),
					priceAfterDiscount: sanitizePrice(
						PRICING.ELITE_ANNUAL / 12 - (PRICING.ELITE_ANNUAL / 12) * (this.availableDiscount ? this.availableDiscount / 100 : 0)
					),
					paymentPrice: sanitizePrice(PRICING.ELITE_ANNUAL - PRICING.ELITE_ANNUAL * (this.availableDiscount ? this.availableDiscount / 100 : 0)),
					plan: SUBSCRIPTION.ELITE_ANNUALLY,
				},
			},
		};
		return pricing;
	}

	get isAnnualPlansOnly() {
		const pricing = this.pricing;
		return pricing.elite.monthly == undefined && pricing.pro.monthly == undefined;
	}

	get subscription() {
		return this.subscriptionInfo?.subscription;
	}

	get paymentStatus() {
		return this.subscriptionInfo.paymentStatus;
	}

	get subscriptionDueRenewal() {
		return this.paymentStatus === PAYMENT_STATUS.PENDING_RENEWAL;
	}

	get subscriptionPending() {
		return this.paymentStatus === PAYMENT_STATUS.PAYMENT_ACTION_REQUIRED;
	}

	get subscriptionFailed() {
		return this.paymentStatus === PAYMENT_STATUS.PAYMENT_REQUIRES_METHOD;
	}

	get subscriptionActive() {
		if (this.paymentStatus === PAYMENT_STATUS.CANCELLED) {
			return new Date(this.subscriptionInfo?.currentPeriodEnd) > new Date();
		}
		return this.paymentStatus === PAYMENT_STATUS.ACTIVE || this.paymentStatus === PAYMENT_STATUS.FREE_TRIAL;
	}

	get isProUser() {
		return this.isPro;
	}

	get isEliteUser() {
		return this.isElite;
	}

	async handleGetPro() {
		await this.openSubscriptionPaymentPortal({ newSubscription: this.pricingBaseInfo.pro.plan, openPaymentMethodModal: true });
	}

	async handleGetElite() {
		await this.openSubscriptionPaymentPortal({ newSubscription: this.pricingBaseInfo.elite.plan, openPaymentMethodModal: true });
	}

	async openSubscriptionPaymentPortal(payload: {
		newSubscription: SUBSCRIPTION;
		channel?: PaymentChannel;
		openPaymentMethodModal?: boolean;
		promotionCode?: string;
	}) {
		if (this.isPromotionActive) {
			payload = { ...payload, promotionCode: this.currentPromotion.promotionCode };
		}
		if (this.isCodePromotionActive) {
			payload = { ...payload, promotionCode: this.codePromotion.promotionCode };
		}
		if (!this.isAuthenticated) {
			this.$pixcap.$services.authService.setOnAuthenticated(() => {
				this.$pixcap.$services.authService.setOnAuthenticated(undefined);
				this.openSubscriptionPaymentPortal(payload);
			});
			this.showAuthenticationModal();
			return;
		}
		this.isLoadingState = { [payload.newSubscription]: true };
		this.isLoading = true;
		const portalUrl = await UserActions.fetchSubscriptionPaymentPortal(this.$store, payload);
		this.isLoadingState = {};
		this.isLoading = false;
		if (!portalUrl) return;

		if (payload.channel == PaymentChannel.PADDLE) {
			this.handlePaddleCheckout(portalUrl);
		} else {
			this.handlePaymentPortal(portalUrl);
		}
		this.selectedSubscriptionInfo = { subscription: payload.newSubscription, channel: payload.channel };
		window.addEventListener('message', this.messagePortalUpgradeListener);
		this.$pixcap.$mixpanel.track('Click Upgrade Plan', { Subscription: payload.newSubscription, Provider: payload.channel });
	}

	async openCustomerPortal() {
		this.isLoading = true;
		const portalUrl = await UserActions.fetchCustomerPortal(this.$store);
		this.isLoading = false;
		if (!portalUrl) return;
		this.handlePaymentPortal(portalUrl);
		window.addEventListener('message', this.messagePortalUpgradeListener);
		this.$pixcap.$mixpanel.track('Click Change Plan');
	}

	async openToppedCreditsPaymentPortal(payload: { quantity: number; channel?: string; openPaymentMethodModal?: boolean }) {
		if (!this.isAuthenticated) {
			this.$pixcap.$services.authService.setOnAuthenticated(() => {
				this.$pixcap.$services.authService.setOnAuthenticated(undefined);
				this.openToppedCreditsPaymentPortal(payload);
			});
			this.showAuthenticationModal();
			return;
		}
		this.isLoading = true;
		const portalUrl = await UserActions.fetchTopUpCreditsPortal(this.$store, payload);
		this.isLoading = false;
		if (!portalUrl) return;
		if (payload.channel == PaymentChannel.PADDLE) {
			this.handlePaddleCheckout(portalUrl);
		} else {
			this.handlePaymentPortal(portalUrl);
		}
		this.topUpCreditsInfo = { topUpCreditsQuantity: payload.quantity, channel: payload.channel, initialCredits: this.getAvailableCredits };
		window.addEventListener('message', this.messagePortalUpgradeListener);
		this.$pixcap.$mixpanel.track('Click Top Up');
	}

	async openCartCheckoutPaymentPortal(payload: { itemIds?: string[]; packIds?: string[]; channel?: string }) {
		if (!this.isAuthenticated) {
			this.$pixcap.$services.authService.setOnAuthenticated(() => {
				this.$pixcap.$services.authService.setOnAuthenticated(undefined);
				this.openCartCheckoutPaymentPortal(payload);
			});
			this.showAuthenticationModal();
			return;
		}
		this.isLoading = true;
		const portalUrl = await UserActions.fetchCartCheckoutPortal(this.$store, payload);
		this.isLoading = false;
		if (!portalUrl) return;
		if (payload.channel == PaymentChannel.PADDLE) {
			this.handlePaddleCheckout(portalUrl);
		} else {
			this.handlePaymentPortal(portalUrl);
		}
		this.cartInfo = { itemsId: payload.itemIds, packsId: payload.packIds, channel: payload.channel };
		window.addEventListener('message', this.messagePortalUpgradeListener);
		this.$pixcap.$mixpanel.track('Click Cart Checkout');
	}

	async handleMessagePortalUpgrade(e: MessageEvent) {
		const checkoutSource = this.upgradeModalState?.triggerCase;
		const messageData = e.data;
		const { message, status } = messageData || {};
		// ignore any other message
		if (message != 'ok') return;
		if (status == PORTAL_PAYMENT_STATUS.SUCCESS && (this.selectedSubscriptionInfo || this.topUpCreditsInfo || this.cartInfo)) {
			if (this.cartInfo) {
				// 	Remove bought item from vuex + localStorage
				if (this.cartInfo.itemsId.length > 0) {
					this.cartInfo.itemsId.forEach((id) => {
						UserMutations.removeCartItemId(this.$store, id);
						UserMutations.setTemporaryUserPurchasedHistory(this.$store, [id]);
					});
				}
				if (this.cartInfo.packsId.length > 0) {
					this.cartInfo.packsId.forEach((id) => {
						UserMutations.removeCartItemId(this.$store, id);
						UserMutations.setTemporaryUserPurchasedHistory(this.$store, [id]);
					});
				}
				if (this.cartInfo.channel === PaymentChannel.PAYPAL || this.cartInfo.channel === PaymentChannel.PADDLE) {
					UserMutations.showPortalPaymentStatusModal(this.$store, {
						status,
						channel: PaymentChannel.PAYPAL,
						isTopUpEvent: false,
						isBuyItemEvent: true,
					});
				}
				UserMutations.setShowCartPaymentStatusPopoverModal(this.$store, this.cartInfo);
			} else {
				UserMutations.showPortalPaymentStatusModal(this.$store, {
					status,
					...(this.selectedSubscriptionInfo || this.topUpCreditsInfo),
					isTopUpEvent: this.topUpCreditsInfo != null,
				});
				this.selectedSubscriptionInfo = null;
				UserActions.fetchPersonalData(this.$store, true);
			}

			this.$pixcap.$mixpanel.track('Complete Stripe Checkout', { Success: true, 'Checkout Source': checkoutSource });
		} else {
			this.$pixcap.$mixpanel.track('Complete Stripe Checkout', { Success: false, 'Checkout Source': checkoutSource });
		}
		UserMutations.setUpgradeModalState(this.$store, null);
		UserMutations.setSelectedSubscriptionForPayment(this.$store, null);
		//@ts-ignore
		e.source.postMessage({ ack: true }, e.origin);
		if (this.handleCloseMethod) this.handleCloseMethod();
	}

	handlePaymentPortal(url: string) {
		const paymentPortalWindow = window.open(url);
		if (!paymentPortalWindow || paymentPortalWindow.closed) window.location.href = url;
	}

	handlePaddleCheckout(params: string) {
		if (this.paddle) {
			let transactionId = (params as string)?.split('=')[1];
			this.paddle.Checkout.open({
				transactionId: transactionId,
			});
		}
	}

	showAuthenticationModal() {
		UserMutations.showAuthenticationModal(this.$store, true);
	}

	created() {
		this.messagePortalUpgradeListener = this.handleMessagePortalUpgrade.bind(this);
		const _this = this;
		initializePaddle({
			environment: _PIXCAP_ENV.APP_ENVIRONMENT == 'production' ? 'production' : 'sandbox',
			token: _PIXCAP_ENV.PADDLE_TOKEN,
			checkout: {
				settings: {
					displayMode: 'overlay',
					allowedPaymentMethods: ['card', 'google_pay'],
					allowLogout: false,
					showAddDiscounts: true,
					locale: this.locale,
				},
			},
			eventCallback: (data: any) => {
				if (data.name === 'checkout.completed') {
					this.paddle.Checkout.close();
					window.postMessage({ message: 'ok', status: PORTAL_PAYMENT_STATUS.SUCCESS }, '*');
				}
			},
		})
			.then((paddleInstance) => {
				this.paddle = paddleInstance;
			})
			.catch((_error) => {});
	}

	beforeDestroy() {
		this.$pixcap.$services.authService.setOnAuthenticated(undefined);
		window.removeEventListener('message', this.messagePortalUpgradeListener);
		this.messagePortalUpgradeListener = null;
		this.paddle = null;
	}
}
