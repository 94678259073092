
	import { Component, Mixins } from 'vue-property-decorator';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import { SUBSCRIPTION, SUBSCRIPTION_TYPE, SUBSCRIPTION_UPGRADE_CASE, UPGRADE_PLAN_TRIGGER_TYPE } from '@pixcap/ui-core/models/store/user.interface';
	import { UPGRADE_FEATURE_SERVICES } from '@pixcap/ui-core/constants/subscription.constants';
	import UpgradeSubscriptionMixin from '@pixcap/ui-core/components/payment/UpgradeSubscriptionMixin';
	import SliderSwitch from '@pixcap/ui-library/components/SliderSwitch.vue';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import CountdownInput from '@pixcap/ui-library/components/Inputs/CountdownInput.vue';
	/// icons list
	import IconCheck from '@pixcap/ui-library/components/Icons/IconCheck.vue';
	import IconCrownSimple from '@pixcap/ui-library/components/Icons/IconCrownSimple.vue';
	import IconDownloadExport from '@pixcap/ui-library/components/Icons/IconDownloadExport.vue';
	import IconImage from '@pixcap/ui-library/components/Icons/IconImage.vue';
	import IconInfo from '@pixcap/ui-library/components/Icons/IconInfo.vue';
	import IconPaintBrush from '@pixcap/ui-library/components/Icons/IconPaintBrush.vue';
	import IconSparkle from '@pixcap/ui-library/components/Icons/IconSparkle.vue';
	import IconSealCheck from '@pixcap/ui-library/components/Icons/IconSealCheck.vue';
	import { ENTERPRISE_UPGRADE_LINK } from '@pixcap/ui-core/constants/app.constants';

	@Component({
		name: 'UpgradeMemberModal',
		components: {
			SliderSwitch,
			CountdownInput,
			BaseModal,
			BodyText,
			HeadingText,
			CaptionText,
			Button,
			IconCheck,
			IconDownloadExport,
			IconCrownSimple,
			IconImage,
			IconInfo,
			IconPaintBrush,
			IconSparkle,
			IconSealCheck,
		},
	})
	export default class UpgradeMemberModal extends Mixins(UpgradeSubscriptionMixin) {
		isProPlanSelected = true;
		UPGRADE_PLAN_TRIGGER_TYPE = UPGRADE_PLAN_TRIGGER_TYPE;

		get planCards() {
			const pricingBaseInfo = this.pricingBaseInfo;
			const pricing = this.pricing;
			return [
				{
					title: 'Pro',
					price: pricingBaseInfo.pro.price,
					isProPlan: true,
					priceBeforeDiscount: pricingBaseInfo.pro.priceBeforeDiscount,
					billingText: pricingBaseInfo.pro.billingText,
					period: '/mo',
					currency: pricing.currencyPrefix,
					label: null,
					selected: this.isProPlanSelected,
					currentPlan: pricingBaseInfo.pro.plan == this.subscription && this.subscriptionActive,
					disabled: this.isEliteFeature,
				},
				{
					title: 'Elite',
					isProPlan: false,
					price: pricingBaseInfo.elite.price,
					priceBeforeDiscount: pricingBaseInfo.elite.priceBeforeDiscount,
					billingText: pricingBaseInfo.elite.billingText,
					period: '/mo',
					currency: pricing.currencyPrefix,
					label: 'RECOMMENDED',
					selected: !this.isProPlanSelected,
					currentPlan: pricingBaseInfo.elite.plan == this.subscription && this.subscriptionActive,
					disabled: false,
				},
			];
		}
		get features() {
			const _features: any[] = this.isProPlanSelected
				? UPGRADE_FEATURE_SERVICES[SUBSCRIPTION_TYPE.PROFESSIONAL]
				: UPGRADE_FEATURE_SERVICES[SUBSCRIPTION_TYPE.ELITE];
			const triggerType = this.upgradeModalState.triggerType;
			const features = [];
			for (let i = 0; i < _features.length; i++) {
				const feature = _features[i];
				const isActive = feature.featureType && (feature.featureType == triggerType || feature.featureType.includes(triggerType));
				if (isActive) {
					features.push({
						...feature,
						active: true,
						order: i > 2 ? 2 : i,
					});
					if (i > 2) features[2].order = i;
				} else {
					features.push({
						...feature,
						active: false,
						order: i,
					});
				}
			}
			return features;
		}

		get isEliteFeature() {
			return this.upgradeModalState.triggerType === UPGRADE_PLAN_TRIGGER_TYPE.GLB_DOWNLOAD;
		}

		get shouldDisableProPlan() {
			if (this.isEliteFeature) return true;
			if (
				this.upgradeModalState.triggerType === UPGRADE_PLAN_TRIGGER_TYPE.AI_STYLIST_EXCEED_LIMIT &&
				[SUBSCRIPTION.PRO_MONTHLY, SUBSCRIPTION.PRO_QUARTERLY, SUBSCRIPTION.PRO_ANNUALLY].includes(this.subscription)
			)
				return true;
			return false;
		}

		get tooltip() {
			if (this.isEliteFeature) return 'This feature is not available for PRO';
			if (this.shouldDisableProPlan) return 'This is your current plan';
			return null;
		}

		get showTopUpButton() {
			const triggerCase = this.upgradeModalState.triggerCase;
			return (
				(triggerCase == SUBSCRIPTION_UPGRADE_CASE.HIT_CREDIT_LIMIT || triggerCase == SUBSCRIPTION_UPGRADE_CASE.TOP_UP_CREDITS) &&
				!this.isUnsubscribedUser
			);
		}

		get hideUpgradePlanOption() {
			const triggerCase = this.upgradeModalState.triggerCase;
			return (
				this.isEliteUser && (triggerCase == SUBSCRIPTION_UPGRADE_CASE.HIT_CREDIT_LIMIT || triggerCase == SUBSCRIPTION_UPGRADE_CASE.TOP_UP_CREDITS)
			);
		}

		get modalHeadInfo() {
			const triggerCase = this.upgradeModalState.triggerCase;
			const isProUser = this.isProUser;
			switch (triggerCase) {
				case SUBSCRIPTION_UPGRADE_CASE.HIT_CREDIT_LIMIT:
					return {
						title: `You’ve used all credits!`,
						description: this.hideUpgradePlanOption
							? 'Contact us to upgrade to the Enterprise plan or top up for more credits.'
							: this.showTopUpButton
							? 'Upgrade now or top up for more. '
							: `Don't worry! Upgrade now for more.`,
					};
				case SUBSCRIPTION_UPGRADE_CASE.TOP_UP_CREDITS:
					return {
						title: this.hideUpgradePlanOption ? 'Unlock more credits!' : `Upgrade now for more credits`,
						description: this.hideUpgradePlanOption ? 'Contact us to upgrade to the Enterprise plan or top up for more credits.' : '',
					};
				case SUBSCRIPTION_UPGRADE_CASE.USE_REMOVE_WATERMARK:
					return {
						title: `Do you want to remove Pixcap logo?`,
						description: `Upgrade now to remove watermarks and access all premium templates & assets!`,
					};
				case SUBSCRIPTION_UPGRADE_CASE.USE_PRO_ITEM:
					return { title: `You’re opening a premium asset!`, description: `Upgrade now to unlock all premium templates & assets!` };
				case SUBSCRIPTION_UPGRADE_CASE.ADD_FAST_SUPER_DOWNLOAD:
					return { title: `Enjoy 3x-faster Super Downloads`, description: '' };
				case SUBSCRIPTION_UPGRADE_CASE.USE_PREMIUM_FEATURE:
				default:
					return { title: `You’re trying a premium feature!`, description: `Upgrade now to unlock all premium features.` };
			}
		}

		handleUpgradeToEnterprise() {
			window.open(ENTERPRISE_UPGRADE_LINK, '_blank');
			this.handleCloseModal();
		}

		handleUpgradePlan() {
			if (this.isProPlanSelected) this.handleGetPro();
			else this.handleGetElite();
		}

		handleOpenTopUpModal() {
			UserMutations.setShowTopUpCreditsModal(this.$store, true);
			this.handleCloseModal();
		}

		handleSelectPlan(isProPlanSelected: boolean) {
			if (isProPlanSelected != this.isProPlanSelected) this.isProPlanSelected = isProPlanSelected;
		}

		handleCloseModal() {
			UserMutations.setUpgradeModalState(this.$store, null);
		}

		handleDefaultPlan() {
			this.isProPlanSelected = this.upgradeModalState.initPlan == SUBSCRIPTION_TYPE.ELITE ? false : !this.isProUser;
		}

		created() {
			this.handleDefaultPlan();
			this.$pixcap.$mixpanel.track('Open Upgrade Member Modal', { 'Trigger Reason': this.upgradeModalState.triggerCase });
		}
	}
