import { Component, Vue, ProvideReactive } from 'vue-property-decorator';
import { AppState, NAMESPACE as APP_NAMESPACE } from '@pixcap/ui-core/models/store/app.interface';
import { mapState } from 'vuex';

@Component({
	name: 'ABTestingMixin',
	computed: {
		...mapState(APP_NAMESPACE, {
			abTesting: (state: AppState) => state.abTesting,
		}),
	},
})
export default class ABTestingMixin extends Vue {
	@ProvideReactive() abTestingVariant: string[] = null;

	abTesting: AppState['abTesting'];

	populatingAbTestingVariant() {
		if (this.abTesting) {
			this.abTestingVariant = Object.keys(this.abTesting).filter((item) => item !== 'id');
		}
	}

	mounted() {
		this.populatingAbTestingVariant();
	}
}
