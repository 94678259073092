
	import { Component, Mixins } from 'vue-property-decorator';
	import UpgradeSubscriptionMixin from '@pixcap/ui-core/components/payment/UpgradeSubscriptionMixin';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user/wrapper';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';

	@Component({
		name: 'CreditTopUpModal',
		components: {
			Button,
			BodyText,
			HeadingText,
			BaseModal,
		},
	})
	export default class CreditTopUpModal extends Mixins(UpgradeSubscriptionMixin) {
		get creditBundles() {
			return this.getPricingForUser.CREDIT_BUNDLES;
		}

		get currentBundle() {
			return this.creditBundles[this.selectedBundleIndex];
		}

		get currencyPrefix() {
			return this.getPricingForUser.CURRENCY_PREFIX;
		}

		selectedBundleIndex = 0;

		handleSelectBundle(index: number) {
			this.selectedBundleIndex = index;
		}
		async handlePurchase() {
			await this.openToppedCreditsPaymentPortal({ quantity: this.currentBundle.CREDITS, openPaymentMethodModal: true });
			this.handleCloseModal();
		}
		handleCloseModal() {
			UserMutations.setShowTopUpCreditsModal(this.$store, false);
		}
	}
